@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap');
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: 0.2s ease-in;
}

ul,
ol {
  list-style: none;
}

img,
video {
  display: block;
  max-width: 100%;
}

.PrimaryBtn {
  padding: 12px 20px;
  border: none;
  background: rgb(39, 119, 238);
  color: #f6f6f6;
}

.SecondaryBtn {
  padding: 10px 18px;
  border: 2px solid rgb(57, 134, 250);
  color: #010606;
  margin-right: 1rem;
}

.PrimaryBtn:hover {
  background: rgb(4, 99, 240);
}

.SecondaryBtn:hover {
  background: #e7f1fa;
  color: #000;
}

.Container {
  max-width: 1000px;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.AboutBio {
  text-align: justify;
  max-width: 750px;
  margin: 0 auto;
  line-height: 1.6;
}

.taglineAbout {
  text-align: left;
  max-width: 650px;
  margin: 0;
  line-height: 1.6;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 2rem;
}
.projectWrapper {
  margin-top: -1rem;
}

@media (max-width: 1000px) {
  .Container {
    padding: 0;
  }
}

.avatar-image {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 1rem;
}
